import * as React from 'react';
import {StatesButton, StatesButtonStates, ThreeDotsLoader} from 'wix-ui-tpa';
import {withGlobalProps} from '../../providers/globalPropsProvider';
import {ISantaProps} from '@wix/native-components-infra/dist/src/types/types';
import {IPropsInjectedByViewerScript} from '../../types/app-types';
import {classes} from './AddToCartButton.st.css';
import style from './AddToCartApp.scss';
import classnames from 'classnames';
import {AddToCartActionStatus} from '../../constants';

export enum DataHook {
  Root = 'add-to-cart-button-root',
  AddToCartButton = 'add-to-cart-button',
  AddToCartLoadingIndicator = 'add-to-cart-loading-indicator',
}

export interface IAddToCartProps {
  globals: IPropsInjectedByViewerScript;
}

export class AddToCartAppComp extends React.Component<IAddToCartProps & ISantaProps> {
  constructor(props: IAddToCartProps & ISantaProps) {
    super(props);
    this.reportAppLoaded = this.reportAppLoaded.bind(this);
  }

  public componentDidMount(): void {
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded); // eslint-disable-line @typescript-eslint/unbound-method
  }

  private readonly handleClick = (): void => {
    this.props.globals.handleAddToCart();
  };

  private get buttonState(): StatesButtonStates {
    const {addedToCartStatus} = this.props.globals;
    return addedToCartStatus === AddToCartActionStatus.IN_PROGRESS
      ? StatesButtonStates.IN_PROGRESS
      : StatesButtonStates.IDLE;
  }

  public render(): JSX.Element {
    const {
      globals: {shouldDisableButton, buttonText},
      host: {dimensions},
    } = this.props;

    return (
      <div
        data-hook={DataHook.Root}
        style={{height: dimensions.height}}
        className={classnames({
          [style.disabled]: shouldDisableButton,
        })}>
        <StatesButton
          state={this.buttonState}
          className={classnames(classes.addToCartButton, style.stretchToContainer)}
          inProgressContent={
            <div className={classes.threeDotsButtonWrapper}>
              <ThreeDotsLoader className={classes.threeDotsButton} data-hook={DataHook.AddToCartLoadingIndicator} />
            </div>
          }
          onClick={this.handleClick}
          data-hook={DataHook.AddToCartButton}
          idleContent={buttonText}
          disabled={shouldDisableButton}
          fullWidth
        />
      </div>
    );
  }

  private reportAppLoaded() {
    /* istanbul ignore next: hard to test it */
    this.props.globals.onAppLoaded && this.props.globals.onAppLoaded();
  }
}

export const AddToCartApp = withGlobalProps(AddToCartAppComp);
