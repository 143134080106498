import React from 'react';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import {withLeanStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withLeanStyles';
import {withStrictMode} from '@wix/wixstores-client-common-components/dist/es/src/HOC/strictMode';
import {withGlobalPropsProvider} from '../providers/globalPropsProvider';

export const bootstrapApp = (
  Component: React.ComponentType,
  withStylesHoc: typeof withStyles | typeof withLeanStyles
) => {
  return withStylesHoc(withStrictMode(withGlobalPropsProvider(Component)), {strictMode: true});
};
